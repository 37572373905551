import React, { ReactElement, useEffect, useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Nav, { drawerWidth } from "./Nav";
import { useAuth0 } from "../FischerFramework/FischerOAuth";
import Account from "./Account";
import { NavLinkProps } from "../NavLink/NavLink";
import VersionComponent from "../VersionComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
    },
  })
);

interface HeaderAndNavProps {
  children?: ReactElement<NavLinkProps> | ReactElement<NavLinkProps>[];
  title: string;
}

/**
 * A complex nav component.
 * Will render the header app bar and the side nav.
 * Side nav will render if the children are viewable.
 */
export default ({ children, title }: HeaderAndNavProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState<boolean>(mdUp);
  const { auth0Client, canView, isAuthenticated, user } = useAuth0();
  const hasNav = hasViewableNavLink(children);

  function hasViewableNavLink(
    navList?: ReactElement<NavLinkProps> | ReactElement<NavLinkProps>[]
  ) {
    // If the navList is an array, check each component for can(has)
    if (Array.isArray(navList)) {
      const viewableNavLink = navList.filter((navLink) => {
        return canView(navLink.props.has, navLink.props.hasAny);
      });

      if (viewableNavLink.length) {
        return true;
      }

      // Else if a single component, check can has on just that one
    } else if (navList) {
      return canView(navList.props.has, navList.props.hasAny);
    }

    return false;
  }

  useEffect(() => {
    setOpen(mdUp);
  }, [mdUp]);

  async function logIn() {
    await auth0Client!.loginWithRedirect();
  }

  async function logOut() {
    await auth0Client!.logout({
      returnTo: window.location.origin,
    });
  }

  function handleDrawerToggle() {
    setOpen(!open);
  }

  function onClickNav() {
    if (!mdUp) {
      setOpen(false);
    }
  }

  return (
    <>
      <AppBar
        className={`${classes.appBar} ${open && hasNav && classes.appBarShift}`}
      >
        <Toolbar>
          {hasNav ? (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerToggle}
              className={`${open && classes.menuButtonHidden}`}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          {!open || smUp ? (
            <Typography
              component="h1"
              className={classes.title}
              noWrap
              variant="h6"
            >
              {title}
            </Typography>
          ) : null}
          {isAuthenticated ? (
            <Account logOut={logOut} user={user} />
          ) : (
            <Button color="inherit" onClick={logIn}>
              Log in
            </Button>
          )}
          <VersionComponent />
        </Toolbar>
      </AppBar>

      {hasNav ? (
        <Nav
          handleDrawerToggle={handleDrawerToggle}
          onClick={onClickNav}
          open={open}
        >
          {children}
        </Nav>
      ) : null}
    </>
  );
};
