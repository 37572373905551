import React, { MouseEvent, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import { User } from "../FischerFramework/FischerOAuth";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Menu from "@material-ui/core/Menu";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  })
);

interface LogoutProps {
  logOut: () => void;
  user?: User;
}

export default ({ logOut, user }: LogoutProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  function onClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton color="inherit" onClick={onClick}>
        <AccountCircle />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
      >
        <MenuItem disabled={true}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText>{user?.nickname}</ListItemText>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem
          onClick={() => {
            handleClose();
            logOut();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};
