import React from "react";
import { Grid, Typography } from "@material-ui/core";

export default () => (
  <Grid item>
    <Typography variant="h2" gutterBottom>
      Page not found
    </Typography>
    <Typography>You require additional access</Typography>
  </Grid>
);
