import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import HeaderAndNav from "./components/HeaderAndNav/HeaderAndNav";
import CreateClosingSurvey from "./pages/CreateClosingSurvey/CreateClosingSurvey";
import Welcome from "./pages/Welcome/Welcome";
import ThankYou from "./pages/ThankYou/ThankYou";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Route from "./components/Route/Route";
import NavLink from "./components/NavLink/NavLink";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      transform: "translateZ(0)",
    },
    container: {
      marginBottom: theme.spacing(8),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  })
);

export default ({ logRocketURL }: any) => {
  const classes = useStyles();
  const [viewPopup, setViewPopup] = useState<boolean>(false);
  useEffect(() => {
    let visited = sessionStorage["alreadyVisited"];
    if (!visited) {
      setViewPopup(true);
      sessionStorage["alreadyVisited"] = true;
    }
  }, []);
  const handleClose = () => {
    setViewPopup(false);
  };
  return (
    <div className={classes.root}>
      <Dialog
        open={viewPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation Number"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Closing Associate, please record the following confirmation number:{" "}
            {logRocketURL
              ? (logRocketURL as string).substr(
                  (logRocketURL as string).length - 6
                )
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <HeaderAndNav title="Closing Surveys">
        <NavLink
          exact
          has={[
            "homeowner:manage",
            "homeowner:read",
            "community-site:read",
            "form-state:write",
          ]}
          icon={<ListAltIcon />}
          primary="Closing Survey"
          to="/"
        />
      </HeaderAndNav>
      <Container component="main" className={classes.content} maxWidth="xl">
        <div className={classes.appBarSpacer} />
        <Grid className={classes.container}>
          <Switch>
            <Route path="/thank-you" hasAny>
              <ThankYou />
            </Route>
            <Route
              path="/"
              has={[
                "homeowner:manage",
                "homeowner:read",
                "community-site:read",
                "form-state:write",
              ]}
              fallback={<Welcome />}
            >
              <CreateClosingSurvey logRocketURL={logRocketURL} />
            </Route>
          </Switch>
        </Grid>
      </Container>
    </div>
  );
};
