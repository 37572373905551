import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { useField } from "formik";

interface FormikTextFieldProps {
  name: string;
}

export default (props: FormikTextFieldProps & TextFieldProps) => {
  const [field, meta] = useField(props.name);

  return (
    <TextField
      color="secondary"
      error={meta.touched && !!meta.error}
      helperText={meta.error || ' '}
      inputProps={{
        "data-testid": props.id
      }}
      margin="normal"
      {...field}
      {...props}
    />
  );
};
