import { createContext, useContext } from "react";
import { useAuth0 } from "./FischerOAuth";

interface FetchContext {
  fetch: (fileName: string, init?: RequestInit) => Promise<Response>;
}

export interface FetchClient {
  headers?: HeadersInit;
  hostName: string;
}

export const useFetch = (client?: FetchClient) => {
  const { auth0Client } = useAuth0();

  return useContext(
    createContext<FetchContext>({
      fetch: async (
        fileName: string,
        init?: RequestInit
      ): Promise<Response> => {
        const token = await auth0Client!.getTokenSilently();

        return fetch(client?.hostName + fileName, {
          ...init,
          headers: {
            ...client?.headers,
            ...init?.headers,
            authorization: "Bearer " + token,
            token // @TODO remove this when homeowner removes this requirement
          }
        });
      }
    })
  );
};
