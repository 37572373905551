import React, { ChangeEvent, useEffect } from "react";
import { useField } from "formik";
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "../../../components/FischerFramework/FischerSnackbar";
import { CircularProgress } from "@material-ui/core";
import { useQuery, gql } from "@apollo/client";
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField";
import { useFetch } from "../../../components/FischerFramework/Fetch";
import config from "../../../config";

interface CommunitySite {
  site_number: string;
  is_sapphire_job: boolean;

  community: {
    division: {
      division: string;
      division_name: string;
    };
  };
}

interface Lot {
  LotID: string;
  Type: string;
  BuyerNames?: string[];
}

export const GET_COMMUNITY_SITES_BY_SITE_NUMBER = gql`
  query JobNumberSearch($siteNumber: Mixed!) {
    CommunitySites(
      where: { column: SITE_NUMBER, operator: LIKE, value: $siteNumber }
    ) {
      site_number: JobNumber
      is_sapphire_job: IsLegacy

      community {
        division {
          division: Code
          division_name: Name
        }
      }
    }
    Lots(where: { column: LOT_I_D, operator: LIKE, value: $siteNumber }) {
      LotID
      Type
      BuyerNames
    }
  }
`;

interface JobNumberComboboxProps extends StandardTextFieldProps {
  divisionName: string;
  marketHomeName: string;
  selectionsQuestionsBool: string;
  userName: string;
  setCustomerNameReadonly: (customerNameReadonly: boolean) => void;
  id: string;
  name: string;
  onChange: any;
}

export default (props: JobNumberComboboxProps) => {
  const [, meta, helpers] = useField(props.name);
  const [, , helpersForDivision] = useField(props.divisionName);
  const [, , helpersForMarketHome] = useField(props.marketHomeName);
  const [, , helperForSelectionsQuestions] = useField(props.marketHomeName);
  const [, , helpersForUser] = useField(props.userName);
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>();
  const { captureException } = useSnackbar();
  const { fetch: homeownerFetch } = useFetch(
    config.fetchClients?.homeownerServices
  );

  const skip = !inputValue || !inputValue.match(/^[A-Za-z]{2}/);
  const onChange = props.onChange;
  const { loading, error, data } = useQuery(
    GET_COMMUNITY_SITES_BY_SITE_NUMBER,
    {
      skip,
      variables: {
        siteNumber: "%" + inputValue + "%",
      },
    }
  );

  useEffect(() => {
    if (error && error.networkError) {
      captureException(
        error.networkError,
        "There was an error retrieving jobs. Please email helpdesktfg@fischerhomes.com"
      );
    }
  }, [captureException, error]);

  const options: CommunitySite[] = data?.CommunitySites || [];

  return (
    <Autocomplete
      id={props.id}
      loading={loading}
      noOptionsText={skip ? "Start with two letters (XY)" : "No options"}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={async (event: ChangeEvent<{}>, value: any | CommunitySite) => {
        helpers.setValue(value?.site_number);
        helpersForDivision.setValue(value?.community?.division?.division);
        onChange(value?.site_number !== undefined ? true : false);

        helpersForUser.setValue("");
        props.setCustomerNameReadonly(false);

        if (value?.site_number) {
          if (!value?.is_sapphire_job) {
            // hit lots endpoint
            const lots: Lot[] = data?.Lots || [];

            if (lots && lots.length) {
              const selectedLot = lots.find(
                (lot) => lot.LotID === value?.site_number
              );

              helpersForMarketHome.setValue(selectedLot?.Type === "Market");

              if (selectedLot?.BuyerNames?.length) {
                // We've got buyer names array. Filter out empty strings
                const buyerNamesArray = selectedLot.BuyerNames.filter(Boolean);

                if (buyerNamesArray.length) {
                  helpersForUser.setValue(buyerNamesArray.join(", "));

                  // Disable the Customer Name field
                  props.setCustomerNameReadonly(true);
                }
              }
            } else {
              helpersForMarketHome.setValue(false);
            }
            await homeownerFetch(
              "/jobInformation/?job_number=" + value?.site_number,
              {
                method: "get",
              }
            )
              .then((response) => response.json())
              .then((response) => {
                helperForSelectionsQuestions.setValue(
                  response.jobInformation.SelectionsAct <
                    response.jobInformation.AgreementWritten
                );
              })
              .catch(() => {
                helperForSelectionsQuestions.setValue(false);
              });
          } else {
            await homeownerFetch(
              "/jobInformation/?job_number=" + value?.site_number,
              {
                method: "get",
              }
            )
              .then((response) => response.json())
              .then((response) => {
                helpersForMarketHome.setValue(
                  response.jobInformation.HomeStatus === "Market"
                );
                helperForSelectionsQuestions.setValue(
                  response.jobInformation.SelectionsAct <
                    response.jobInformation.AgreementWritten
                );
              })
              .catch(() => {
                helpersForMarketHome.setValue(false);
                helperForSelectionsQuestions.setValue(false);
              });
          }
        }
      }}
      getOptionSelected={(selected: CommunitySite, value: CommunitySite) =>
        selected.site_number === value.site_number
      }
      getOptionLabel={(option) => option.site_number}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          error={meta.touched && !!meta.error}
          helperText={meta.error || " "}
          label={props.label}
          margin="normal"
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
