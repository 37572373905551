import React, { createContext, useContext, useEffect, useState } from "react";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import {
  Auth0ClientOptions,
  IdToken
} from "@auth0/auth0-spa-js/dist/typings/global";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "./FischerSnackbar";

export interface User extends IdToken {
  sub: string;
}

interface FischerOAuthContext {
  auth0Client?: Auth0Client;

  decodedToken?: any;

  /**
   * Check scopes if user can by permissions
   *
   * @param permissions
   */
  can: (permissions: string[]) => boolean;

  /**
   * Check has as scopes if passed to see if the user can by permissions
   * Check hasAny if the user is logged in
   *
   * @param has
   * @param hasAny
   */
  canView: (has?: string[], hasAny?: boolean) => boolean;

  isAuthenticated: boolean;

  user?: User;
}

const defaultValue = {
  can: () => false,
  canView: () => false,
  isAuthenticated: false
};

const Auth0Context = createContext<FischerOAuthContext>(defaultValue);

export const useAuth0 = () => useContext<FischerOAuthContext>(Auth0Context);

interface FischerOAuthProps extends React.ComponentProps<any> {
  oauth: Auth0ClientOptions;
}

export default ({ children, oauth }: FischerOAuthProps) => {
  const [context, setContext] = useState<FischerOAuthContext>(defaultValue);
  const { push } = useHistory();
  const { captureException } = useSnackbar();

  function can(permissions: string[]) {
    if (context.isAuthenticated) {
      return permissions.reduce((included, permission) => {
        return (
          included && context.decodedToken?.permissions?.includes(permission)
        );
      }, true);
    }

    return false;
  }

  function canView(has?: string[], hasAny?: boolean): boolean {
    return (
      (has === undefined || can(has)) &&
      (hasAny ? context.isAuthenticated : true)
    );
  }

  useEffect(() => {
    async function getAuth0Client() {
      const options = Object.assign({}, oauth, {
        redirect_uri: window.location.origin
      });

      try {
        return await createAuth0Client(options);
      } catch {
        // If create auth0 client fails, then create one manually
        return new Auth0Client(options);
      }
    }

    async function initAuth0() {
      const auth0Client = await getAuth0Client();

      // If we are returning from oauth
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();

        push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      }

      const isAuthenticated = await auth0Client.isAuthenticated();

      if (isAuthenticated) {
        const token = await auth0Client.getTokenSilently();
        const user = await auth0Client.getUser();
        const decodedToken = jwt_decode(token);

        setContext({
          auth0Client,
          decodedToken,
          can: () => false,
          canView: () => false,
          isAuthenticated,
          user
        });
      } else {
        setContext({
          auth0Client,
          can: () => false,
          canView: () => false,
          isAuthenticated
        });
      }
    }

    initAuth0().catch(exception => {
      captureException(exception, "Sorry, we weren't able to log you in");
    });
  }, [captureException, push, oauth]);

  return (
    <Auth0Context.Provider value={Object.assign({}, context, { can, canView })}>
      {context.auth0Client ? children : null}
    </Auth0Context.Provider>
  );
};
