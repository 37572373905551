import * as env from "env-var";
import { Config } from "./components/FischerFramework/FischerFramework";

const config: Config = {
  fetchClients: {
    homeownerServices: {
      hostName: env.get("REACT_APP_HOMEOWNER_URL").required().asString(),
      headers: {
        "Content-type": "application/json",
      },
    },
    rest: {
      hostName: env.get("REACT_APP_REST_BASE_URI").required().asString(),
      headers: {
        "Content-type": "application/json",
      },
    },
    papi: {
      hostName:
        env.get("REACT_APP_CORS_PROXY").required().asString() +
        env.get("REACT_APP_PAPI_BASE_URI").required().asString(),
      headers: {
        "Content-type": "application/json",
      },
    },
  },

  graphClientUri: env.get("REACT_APP_GRAPH_CLIENT_URI").required().asString(),

  oauth: {
    audience: env.get("REACT_APP_OAUTH_AUDIENCE").required().asString(),
    domain: env.get("REACT_APP_OAUTH_DOMAIN").required().asString(),
    client_id: env.get("REACT_APP_OAUTH_CLIENT_ID").required().asString(),
    scope: env.get("REACT_APP_OAUTH_SCOPE").asString(),
  },

  themeOptions: {
    palette: {
      secondary: {
        main: "#e5b000",
      },
      primary: {
        main: "#00366d",
      },
    },
  },
};

export default config;
