import React, { ChangeEvent } from 'react'
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      alignSelf: "flex-start"
    }
  })
);

interface FormikCheckboxProps extends CheckboxProps {
  label: string;
  name: string;
}

export default (props: FormikCheckboxProps) => {
  const [field, meta, helpers] = useField(props.name);
  const classes = useStyles();

  function onChange (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      helpers.setValue('Yes')
    } else {
      helpers.setValue('')
    }
  }

  return (
    <FormControl error={meta.touched && !!meta.error} id={props.id + '-control'} margin="normal">
      <FormControlLabel
        control={
          <Checkbox
            className={classes.checkbox}
            color="secondary"
            data-testid={props.id}
            {...field}
            {...props}
            onChange={onChange}
          />
        }
        id={props.id + '-label'}
        label={props.label}
      />
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.error || ' '}
      </FormHelperText>
    </FormControl>
  );
};
