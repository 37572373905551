import React, { MouseEventHandler } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      display: "flex",
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
  })
);

interface NavProps extends React.ComponentProps<any> {
  handleDrawerToggle: MouseEventHandler;
  onClick: MouseEventHandler;
  open: boolean;
  variant?: "permanent" | "persistent" | "temporary";
}

export default ({
  children,
  handleDrawerToggle,
  onClick,
  open,
  variant,
}: NavProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Drawer
      anchor={theme.direction === "rtl" ? "right" : "left"}
      variant={variant || (smUp ? "permanent" : "temporary")}
      classes={{
        paper: `${classes.drawerPaper} ${!open && classes.drawerPaperClose}`,
      }}
      open={open}
      onClose={handleDrawerToggle}
    >
      <nav onClick={onClick}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <List disablePadding={true}>{children}</List>
      </nav>
    </Drawer>
  );
};
