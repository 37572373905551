import React from "react";
import { Route, RouteProps as RouterRouteProps } from "react-router-dom";
import { useAuth0 } from "../FischerFramework/FischerOAuth";
import Forbidden from "./Forbidden";

interface RouteProps extends RouterRouteProps {
  children: JSX.Element;
  has?: string[];
  hasAny?: boolean;
  fallback?: JSX.Element;
}

export default ({ children, has, hasAny, fallback, ...props }: RouteProps) => {
  const { canView } = useAuth0();

  const fallbackComponent = fallback || <Forbidden />;

  return (
    <Route {...props}>
      {canView(has, hasAny) ? children : fallbackComponent}
    </Route>
  );
};
