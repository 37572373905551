import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  NavLink,
  LinkProps as RouterLinkProps,
  NavLinkProps as RouterNavLinkProps,
} from "react-router-dom";
import { Omit } from "@material-ui/types";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useAuth0 } from "../FischerFramework/FischerOAuth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeNavLink: {
      borderRightWidth: theme.spacing(0.5),
      borderRightStyle: "solid",
      borderRightColor: theme.palette.secondary.main,
    },
  })
);

export interface NavLinkProps extends RouterNavLinkProps {
  fallback?: JSX.Element;
  has?: string[];
  hasAny?: boolean;
  icon?: React.ReactElement;
  primary: string;
}

export default ({
  fallback,
  has,
  hasAny,
  icon,
  primary,
  ...props
}: NavLinkProps) => {
  const classes = useStyles();
  const { canView } = useAuth0();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <NavLink
          activeClassName={classes.activeNavLink}
          ref={ref}
          title={primary}
          {...itemProps}
          {...props}
        />
      )),
    [classes, primary, props]
  );

  const fallbackComponent = fallback || null;

  return canView(has, hasAny) ? (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  ) : (
    fallbackComponent
  );
};
