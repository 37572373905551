import React, { Component, ErrorInfo } from 'react'
import * as Sentry from '@sentry/browser';
import FischerUnknownError from './FischerUnknownError'

export default class FischerBoundary extends Component {
  state = {
    eventId: null,
    hasError: false
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FischerUnknownError eventId={this.state.eventId} />;
    }

    return this.props.children;
  }
}
