import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

// Custom
import PopoverComponent from './PopoverComponent';

// CSS
const useStyles = makeStyles((theme) => ({
  root: {

  },
  list: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

const IconBtnPopoverComponent = (props) => {
  const classes = useStyles();
  const {
    badge, id, icon, anchorOrigin, transformOrigin,
    children, showPopover, setShowPopover,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const badgeCount = (badge >= 1) ? badge : undefined;

  const handleClick = (event) => {
    if (setShowPopover) {
      setShowPopover(true);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (setShowPopover) {
      setShowPopover(false);
    }
    setAnchorEl(null);
  };
  const open = showPopover !== undefined ? showPopover : Boolean(anchorEl);

  return (
      <>
        <IconButton
          className={classes.root}
          aria-describedby={id}
          color="inherit"
          onClick={handleClick}
        >
          <Badge badgeContent={badgeCount} color="secondary">{icon}</Badge>
        </IconButton>
        <PopoverComponent
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {children}
        </PopoverComponent>
      </>
    )
};
// define these for destructuring props
IconBtnPopoverComponent.propTypes = {
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  icon: PropTypes.objectOf(PropTypes.any),
  anchorOrigin: PropTypes.objectOf(PropTypes.any),
  transformOrigin: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
};
// gotta have this if the above prop types are not required
IconBtnPopoverComponent.defaultProps = {
  badge: undefined,
  id: undefined,
  icon: undefined,
  anchorOrigin: undefined,
  transformOrigin: undefined,
  children: undefined,
  showPopover: undefined,
  setShowPopover: undefined,
};

export default IconBtnPopoverComponent;
