import React from 'react';
import FischerTheme from './FischerTheme'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/browser';
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2)
    },
  })
);


export default ({eventId}: {eventId: string | null}) => {
  const classes = useStyles();

  return (
    <FischerTheme>
      <Container className={classes.root}>
        <Typography variant="h1" gutterBottom>
          Something went wrong.
        </Typography>

        {eventId ? (
          <Button onClick={() => Sentry.showReportDialog({ eventId })}>
            Report feedback
          </Button>
        ) : (
          <Typography paragraph>
            Sorry about that. Please
            contact <Link color="secondary" href="mailto:helpdesktfg@fischerhomes.com">
            helpdesktfg@fischerhomes.com
          </Link> for help.
          </Typography>
        )}
      </Container>
    </FischerTheme>
  )
}