import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import FischerFramework from "./components/FischerFramework/FischerFramework";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "./config";
import packageJson from "../package.json";
import LogRocket from "logrocket";
require("dotenv").config();
let useLogRocket: boolean = true;

// Log Rocket session logging
process.env.REACT_APP_LOG_ROCKET_APP_ID
  ? LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID)
  : (useLogRocket = false);
// Google Analytics
ReactGA.initialize("UA-802100-16");

// Sentry error logging
Sentry.init({
  attachStacktrace: true,
  dsn:
    "https://4ec97076a97a4920b78e0eddb44cf6b4@o245272.ingest.sentry.io/2981761",
  environment: process.env.NODE_ENV,
  release: packageJson.version,
});
useLogRocket
  ? LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
      ReactDOM.render(
        <FischerFramework config={config}>
          <App logRocketURL={sessionURL} />
        </FischerFramework>,
        document.getElementById("root")
      );
    })
  : ReactDOM.render(
      <FischerFramework config={config}>
        <App />
      </FischerFramework>,
      document.getElementById("root")
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
